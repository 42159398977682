<template>
  <div>
    <v-container class="container--fluid grid-list-md text-center">
      <panel-group/>
    </v-container>
  </div>
</template>

<script>

import PanelGroup from '@/views/components/Dashboard/PanelGroup.vue';

import AppWidget from '@/views/widget/AppWidget.vue';

export default {
  name: 'Dashboard',
  components: {
    PanelGroup,
    AppWidget,
  },
  data: () => ({
  }),
  methods: {
  },
};
</script>
