<template>
  <v-row
    dense
    class="panel-group"
    v-if="Object.keys(info).length !== 0"
  >
    <panel-group-item
        :title="$t('fields.shoppings')"
        icon="mdi-cart"
        color="green"
        :start="0"
        :end="info.ordersCount"
        :duration="50600"
    />
    <panel-group-item
      :title="$t('fields.all_positions')"
      icon="mdi-library-books"
      color="blue"
      :start="0"
      :end="info.productCount"
      :duration="3200"
    />
    <panel-group-item
      :title="$t('fields.active_positions')"
      icon="mdi-checkbox-multiple-marked"
      color="light-green accent-4"
      :start="0"
      :end="info.productCountActive"
      :duration="3000"
    />
    <panel-group-item
      :title="$t('fields.not_available')"
      icon="mdi-cart-off"
      color="deep-orange accent-2"
      :start="0"
      :end="info.productCountOutStock"
      :duration="1600"
    />
  </v-row>
</template>

<script>
import PanelGroupItem from './PanelGroupItem.vue';
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'PanelGroup',
  components: {
    PanelGroupItem,
  },
  data() {
    return {
      info: {}
    }
  },
  mounted() {
    this.initDashboard().then(function () {
      this.info = this.getDashboard
    }.bind(this))
  },
  computed: {
    ...mapGetters(["getDashboard"])
  },
  methods: {
    ...mapActions(["initDashboard"])
  }
};
</script>
